<template>
  <div v-if="show" class="invoice-box" :class="action" id="invoice" :style="{'padding': '30px 15px','background-color': '#ffffff',
         'direction': (lang == 'ar'? 'rtl': 'ltr'), 'text-align': (lang == 'ar'? 'right': 'left') }">

    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column align-self-center">
        <h2 class="w-100 mb-3" v-if="company && company.business_name">{{company? company.business_name : ''}}</h2>
        <h2 class="w-100 mb-3" v-if="company && company.business_name_en">{{company? company.business_name_en : ''}}</h2>
      </div>
      <div v-if="rel_logo" style="text-align: end;"><img :src="rel_logo" alt="" style="width: 140px;"></div>
    </div>


    <h2 class="text-center mb-3">
      {{ $t('pos_session.pos_session') }}
    </h2>
    <div class="border-content">
      <div class="bg-cc p-3"><h3 class="mb-0"><span class="box-title"></span> {{$t('Basic_pos_session_data')}}</h3></div>
      <div>
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>{{ $t('pos_session.session') }}</th>
            <th>{{ $t('pos_session.employee') }}</th>
            <th>{{ $t('pos_session.opened_date') }} - {{ $t('pos_session.closed_date') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ data.session.id }}</td>
            <td>{{ data.session.employee_name  }}</td>
            <td>{{ data.session.opened_date }} - {{ data.session.closed_date }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>{{ $t('pos_session.store') }}</th>
            <th>{{ $t('pos_session.user') }}</th>
            <th>{{ $t('pos_session.device') }} - {{ $t('pos_session.shift') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ data.session.store_name }}</td>
            <td>{{ data.session.user_name }}</td>
            <td>{{ data.session.device_name }} - {{ data.session.shift_name }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>{{ $t('pos_session.invoice_code_from') }}</th>
            <th>{{ $t('pos_session.invoice_code_to') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ data.session.invoice_code_from }}</td>
            <td>{{ data.session.invoice_code_to }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="bg-cc p-5">
        <h3><span class="box-title"></span> {{ $t('pos_session.statistics') }}</h3>
      </div>
      <div>
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>{{ $t('pos_session.invoices') }}
            <p class="mb-0">{{ $t('pos_session.count') }} - {{ $t('pos_session.total_total') }}</p>
            </th>
            <th>{{ $t('pos_session.payments') }}
              <p class="mb-0">{{ $t('pos_session.count') }} - {{ $t('pos_session.total_total') }}</p>
            </th>
            <th>{{ $t('pos_session.refunds_credit_note') }}
              <p class="mb-0">{{ $t('pos_session.count') }} - {{ $t('pos_session.total_total') }}</p>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ data.session.invoices_count }} - {{ data.session.invoice_total }}</td>
            <td>{{ data.session.payment_info.summary.count }} - {{ data.session.payment_info.summary.total }}</td>
            <td>
              <p class="mb-0">{{ $t('pos_session.credit_note') }} : {{ data.session.count_credit }} - {{ data.session.total_credit }}</p>
              <p class="mb-0">{{ $t('pos_session.refunds') }}: {{ data.session.count_refund }} - {{ data.session.total_refund }}</p>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>{{ $t('pos_session.cash_movements_in') }}
              <p class="mb-0">{{ $t('pos_session.count') }} - {{ $t('pos_session.total_total') }}</p>
            </th>
            <th>{{ $t('pos_session.cash_movements_out') }}
              <p class="mb-0">{{ $t('pos_session.count') }} - {{ $t('pos_session.total_total') }}</p>
            </th>
            <th>{{ $t('pos_session.total_discounts') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ data.session.count_cash_movements_in }} - {{ data.session.total_cash_movements_in }} {{currency_name}}</td>
            <td>{{ data.session.count_cash_movements_out }} - {{ data.session.total_cash_movements_out }} {{currency_name}}</td>
            <td>
              {{ data.session.total_discount }} {{currency_name}}
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>{{ $t('pos_session.total_tax') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{ data.session.invoice_total_tax }} {{currency_name}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="bg-cc p-5" v-if="data.session.payment_info && data.session.payment_info.payments">
        <h3><span class="box-title"></span> {{ $t('pos_session.payment_details') }}</h3>
      </div>
      <div v-if="data.session.payment_info && data.session.payment_info.payments">
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>{{ $t('pos_session.cash') }}
              <p class="mb-0">{{ $t('pos_session.count') }} - {{ $t('pos_session.total_total') }}</p>
            </th>
            <th>{{ $t('pos_session.card') }}
              <p class="mb-0">{{ $t('pos_session.count') }} - {{ $t('pos_session.total_total') }}</p>
            </th>
            <th>{{ $t('pos_session.bank') }}
              <p class="mb-0">{{ $t('pos_session.count') }} - {{ $t('pos_session.total_total') }}</p>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <p v-if="data.session.payment_info && data.session.payment_info.payments.cash" class="mb-0">{{ data.session.payment_info.payments.cash.count }} - {{ data.session.payment_info.payments.cash.amount }}  {{currency_name}}</p>
            </td>
            <td>
              <p v-if="data.session.payment_info && data.session.payment_info.payments.card" class="mb-0">{{ data.session.payment_info.payments.card.count }} - {{ data.session.payment_info.payments.card.amount }}  {{currency_name}}</p>
            </td>
            <td>
              <p v-if="data.session.payment_info && data.session.payment_info.payments.bank" class="mb-0">{{ data.session.payment_info.payments.bank.count }} - {{ data.session.payment_info.payments.bank.amount }}  {{currency_name}}</p>
            </td>

          </tr>
          </tbody>
        </table>
      </div>
      <div class="bg-cc p-5" v-if="data.invoices && data.invoices.length">
        <h3><span class="box-title"></span> {{ $t('session_bills') }}</h3>
      </div>
      <div v-if="data.invoices && data.invoices.length">
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('payment_sales_invoices.invoice_code') }}</th>
            <th>{{ $t('date_time') }}</th>
            <th> {{$t('extra_discount.invoice_total')}} </th>
<!--            <th>{{$t('total_before_taxes')}}</th>-->
<!--            <th>{{$t('tax.tax')}}</th>-->
            <th>{{$t('print_setting.discount_calc')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(invoice, idx) in data.invoices">
            <td>{{idx+1}}</td>
            <td>{{invoice.code}}</td>
            <td>{{invoice.invoice_date}}</td>
            <td>{{invoice.invoice_total}}</td>
<!--            <td>{{invoice.invoice_total_before_taxes}}</td>-->
<!--            <td>{{invoice.invoice_total_taxes}}</td>-->
            <td>{{invoice.invoice_total_discount}}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="bg-cc p-5" v-if="data.cash_movements_in && data.cash_movements_in.length">
        <h3><span class="box-title"></span> {{ $t('cash_movements_in') }}</h3>
      </div>
      <div v-if="data.cash_movements_in && data.cash_movements_in.length">
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('number') }}</th>
            <th>{{ $t('date_time') }}</th>
            <th> {{$t('amount')}} </th>
            <th> {{$t('currency')}} </th>
            <th>{{$t('type')}}</th>
            <th>{{$t('type_name')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(cash_movement_in, idx) in data.cash_movements_in">
            <td>{{idx+1}}</td>
            <td>{{cash_movement_in.id}}</td>
            <td>{{cash_movement_in.transaction_date}}</td>
            <td>{{cash_movement_in.cash_movement_amount}}</td>
            <td>{{ cash_movement_in.currency_name}}</td>
            <td>{{cash_movement_in.type_type_name}}</td>
            <td>{{cash_movement_in.staff_name}}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="bg-cc p-5" v-if="data.cash_movements_out && data.cash_movements_out.length">
        <h3><span class="box-title"></span> {{ $t('cash_movements_out') }}</h3>
      </div>
      <div v-if="data.cash_movements_out && data.cash_movements_out.length">
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('number') }}</th>
            <th>{{ $t('date_time') }}</th>
            <th> {{$t('amount')}} </th>
            <th> {{$t('currency')}} </th>
            <th>{{$t('type')}}</th>
            <th>{{$t('type_name')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(cash_movement_out, idx) in data.cash_movements_out">
            <td>{{idx+1}}</td>
            <td>{{cash_movement_out.id}}</td>
            <td>{{cash_movement_out.transaction_date}}</td>
            <td>{{cash_movement_out.cash_movement_amount}}</td>
            <td>{{ cash_movement_out.currency_name}}</td>
            <td>{{cash_movement_out.type_type_name}}</td>
            <td>{{cash_movement_out.staff_name}}</td>
          </tr>
          </tbody>
        </table>
      </div>


      <div class="bg-cc p-5" v-if="data.cash_category_before && data.cash_category_before.length">
        <h3><span class="box-title"></span> {{ $t('cash_category_before') }}</h3>
      </div>
      <div v-if="data.cash_category_before && data.cash_category_before.length">
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('cash_category_name') }}</th>
            <th>{{ $t('number') }}</th>
            <th> {{$t('amount')}} </th>
            <th> {{$t('currency')}} </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(cash_category_before, idx) in data.cash_category_before">
            <td>{{idx+1}}</td>
            <td>{{cash_category_before.cash_category_name}}</td>
            <td>{{cash_category_before.cash_category_no}}</td>
            <td>{{cash_category_before.amount}}</td>
            <td>{{ cash_category_before.currency_name}}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="bg-cc p-5" v-if="data.cash_category_after && data.cash_category_after.length">
        <h3><span class="box-title"></span> {{ $t('cash_category_after') }}</h3>
      </div>
      <div v-if="data.cash_category_after && data.cash_category_after.length">
        <table class="table table-bordered mb-0" :style="{'text-align': (lang == 'ar'? 'right': 'left')}">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('cash_category_name') }}</th>
            <th>{{ $t('number') }}</th>
            <th> {{$t('amount')}} </th>
            <th> {{$t('currency')}} </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(cash_category_after, idx) in data.cash_category_after">
            <td>{{idx+1}}</td>
            <td>{{cash_category_after.cash_category_name}}</td>
            <td>{{cash_category_after.cash_category_no}}</td>
            <td>{{cash_category_after.amount}}</td>
            <td>{{ cash_category_after.currency_name}}</td>
          </tr>
          </tbody>
        </table>
      </div>



    </div>
  </div>

</template>

<script>
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import ApiService from "@/core/services/api.service";
import jsPdfExport from "@/core/config/jsPdfExport";
import html2pdf from "html2pdf.js";


export default {
  name: "pos-invoice",

  data() {
    return {
      lang: this.$i18n.locale,
      mainRoute: 'pos/pos-sessions',
      data: {},
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      action: this.$route.params.action ? this.$route.params.action : null,
      show: false,
      currency_name: null,
      company: [],
    }
  },
  computed: {
    rel_logo: function () {
      if (this.company && this.company.logo_url) {
        return this.company.logo_url;
      } else {
        // return window.location.origin + '/media/logos/sajlha_logo_print.png';
        return '';
      }

    },
    fileName: function (){
      return this.$t('pos_session.pos_session');
      // let _invoice_label = this.$i18n.locale ==  'ar' ? 'فاتورة مبيعات' : 'Sales invoice';
      // let _customer_name = (this.data.customer && this.data.customer.fullname) ? this.data.customer.fullname : '';
      // let _invoice_code = (this.data.invoice_code) ? this.data.invoice_code : '';
      // return `${_invoice_label} - ${_customer_name} - ${_invoice_code}`;
    }
  },
  methods: {
    printInvoice() {
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    completedForPrint(){
      let imgs = document.images,
          len = imgs.length,
          counter = 0;

      [].forEach.call( imgs, function( img ) {
        if(img.complete)
          incrementCounter();
        else
          img.addEventListener( 'load', incrementCounter, false );
      } );
      function incrementCounter() {
        counter++;
        if ( counter === len ) {
          window.print();
          // window.close();
        }
      }

    },
    exportPDF(_id) {
      let that = this;
      if (that.action == 'pdf') {
        // const opt = { pagebreak: {avoid: ['tr','.bg-cc', 'thead']}, margin: [5, 0, 10, 0], filename: this.fileName, image: { type: 'jpeg', quality: 2 }, html2canvas: {useCORS: true, scrollY: 0 }, jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' } };
        const opt = { pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }, margin: [5, 0, 10, 0], filename: this.fileName, image: { type: 'jpeg', quality: 2 }, html2canvas: {useCORS: true, scrollY: 0 }, jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' } };
        let _html2pdf = html2pdf().from(document.getElementById(_id)).set(opt).save();
      }else {
        window.print();
      }

    },
    async getData() {
      let _id = this.idEditing;
      await ApiService.get(`${this.mainRoute}/${_id}/print`).then((response) => {
        this.data = response.data.data;
        if(response.data.data.session && response.data.data.session.currency_name){
          this.currency_name = response.data.data.session.currency_name;
        }
        if(response.data.data.company){
          this.company = response.data.data.company
        }
        this.show = true;
      });
    },
  },
  mounted() {
    let promise = this.getData();
    Promise.all([promise]).then(() => {
      this.printInvoice();
    });
    // window.print();
  }
}
</script>

<style>

#invoice {
  font-family: "AlHurra";
  font-weight: 400 !important;
}
.invoice-box {

  border: 1px solid #fff;

  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #000;


  /* padding: 65px 40px; */
  background-color: rgb(255, 255, 255);

  height: auto;
  /* margin: 20px auto; */
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5px 20px;

}
.invoice-box.pdf tr, .invoice-box.pdf .bg-cc{
  /*page-break-before: auto;*/
  page-break-after: auto;

}


/** RTL **/

.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

body > *{
  float: right !important;
  direction: rtl !important;
  text-align: right;
}
html{
  direction: rtl !important;
}
</style>
